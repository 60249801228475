import Constants from '../utils/Constants';

const images = require.context('../assets/images/menu', true);
const pdfs = require.context('../assets/documents', true);

const imageList: Array<string> = images.keys().map((image: any) => images(image));
const pdfList: Array<string> = pdfs.keys().map((pdf: any) => pdfs(pdf));

interface MenuItem {
  title: string;
  imgPath: string;
  pdfPath: string;
}

const WIFI: MenuItem = {
  title: 'WiFi',
  imgPath: imageList.find((x) => x.includes(Constants.WIFI)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.WIFI)) || '',
};

const PARKING: MenuItem = {
  title: 'Parking',
  imgPath: imageList.find((x) => x.includes(Constants.PARKING)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.PARKING)) || '',
};

const EXCURSIONS: MenuItem = {
  title: 'TRIPS / EXCURSIONS ',
  imgPath: imageList.find((x) => x.includes(Constants.EXCURSIONS)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.EXCURSIONS)) || '',
};

const TRANSFERS: MenuItem = {
  title: 'Transfers',
  imgPath: imageList.find((x) => x.includes(Constants.TRANSFERS)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.TRANSFERS)) || '',
};

const INFO_BOOK: MenuItem = {
  title: 'Info Book',
  imgPath: imageList.find((x) => x.includes(Constants.INFO_BOOK)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.INFO_BOOK)) || '',
};

const MenuList: Array<MenuItem> = [WIFI, PARKING, EXCURSIONS, TRANSFERS, INFO_BOOK];

export default MenuList;
